import React from 'react'
// import { Link } from "gatsby-plugin-intl"
// import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
// import Button from "../components/Button"
// import Legend from "../components/Legend"
import { Grid, Row, Col } from 'react-flexbox-grid'
import Logo from "../components/Logo"
// import ScrollIntoView from 'react-scroll-into-view'

// import logoFull from "../assets/svg/logo-full.svg"
// import scrollDown from "../assets/svg/scroll-down.svg"
// import scrollTop from "../assets/svg/scroll-top.svg"
import bgEsper from "../assets/svg/bg-esperluette.svg"
// import bgEsperHome from "../assets/svg/bg-esperluette-home.svg"
// import pastImplan from "../assets/svg/pastilleJaune.svg"
// import pastInter from "../assets/svg/pastillemarron.svg"

// import SVGMap1200 from "../images/carte_1200px.svg"
// import { TooltipParis, TooltipVenise, TooltipTokyo } from "../components/MapTooltips.js"
// import { ZoneParis, ZoneVenise, ZoneTokyo } from "../components/MapZones.js"

const CreditsPage = () => (
  <Layout>
    <SEO title="Credits" />
    <section className="section-wrapper" id="errordocument">
      <img className="bg-esper" src={bgEsper} alt="" />
      <Grid fluid>
        <Row className="hat">
          <Col mdOffset={1} xs={12} md={5}>
            <h1>Credits</h1><br />
            <h2 className="small">Création / Conception</h2>
            <p>
              <strong>Point Ligne Plan</strong><br />
              <a href="https://www.pointligneplan.fr">pointligneplan.fr</a>
            </p>
            <br />

            <h2 className="small">Crédits photos</h2>
            <p>
              <strong>Adobe Stock</strong><br />
              <a href="https://stock.adobe.com">stock.adobe.com</a>
            </p>
            <p>
              <strong>Unsplash</strong><br />
              <a href="https://www.unsplash.com">unsplash.com</a>
            </p>
            <br />

            <h2 className="small">Hébergement</h2>
            <p>
              OVH
            </p>
          </Col>
        </Row>
      </Grid>
    </section>
    <Logo />
  </Layout>
)

export default CreditsPage
